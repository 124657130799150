function getResp(url, callback, body)
{
  var requester = null;
  if (window.XMLHttpRequest)
    {
      requester = new XMLHttpRequest();
    }
  else
    if (window.ActiveXObject)
      {
        var version = 6;
	while (version >= 3 && !requester)
          {
            try       { requester = new ActiveXObject('Msxml2.XMLHTTP.' + i + '.0'); }
            catch (e) { --version;                                                   }
          }
        if (!requester)
          {
            try       { requester = new ActiveXObject('Microsoft.XMLHTTP');          }
            catch (e) {                                                              }
          }
      }
  if (requester)
    {
      requester.onreadystatechange = function() { if (requester.readyState === 4 && requester.status === 200 && callback) { callback(requester.responseText); } };
      //requester.open('GET', url, true);
      //requester.send(null);
      requester.open(body ? 'POST' : 'GET', url, true);
      if (body)
        {
          requester.setRequestHeader("Content-type", "application/x-www-form-urlencoded;charset=UTF-8");
          // requester.setRequestHeader("Content-length", body.length);
        }
      requester.send(body ? body : null);
    }
  else
    {
      xml = document.createElement('xml');
      if (xml && typeof(xml.addBehavior) !== 'undefined')
        {
          xml.addBehavior('#default#download');
          xml.startDownload(url, callback);
        }
    }
}

function openSubMenu()
{
  var subMenuObj=document.getElementById('subMenu');
  if ( subMenuObj )
    {
      if ( subMenuObj.style.display!='block' )
        {
          subMenuObj.style.display='block';
	}
      else
        {
          subMenuObj.style.display='none';
	}
    }
}

function closeSubMenu()
{
  var subMenuObj=document.getElementById('subMenu');
  if ( subMenuObj )
    {
      subMenuObj.style.display='none';
    }
}

function changePriceInputs(){
	if(document.getElementById('pr1I').style.display != 'block') {
		document.getElementById('pr1I').style.display = 'block';
		document.getElementById('pr2I').style.display = 'block';

		document.getElementById('pr1S').style.display = 'none';
		document.getElementById('pr2S').style.display = 'none';
	}else{
		document.getElementById('pr1I').style.display = 'none';
		document.getElementById('pr2I').style.display = 'none';

		document.getElementById('pr1S').style.display = 'block';
		document.getElementById('pr2S').style.display = 'block';
	}
}

function showAllPS(){
	if(document.getElementById('popularSearchesALL').style.display == 'none' || document.getElementById('popularSearchesALL').style.display == ''){
		document.getElementById('popularSearchesALL').style.display = 'block';
		document.getElementById('seeAllPS').innerHTML = 'Скрий';
	}else{
		document.getElementById('popularSearchesALL').style.display = 'none';
		document.getElementById('seeAllPS').innerHTML = 'Виж всички';
	}
}

function checkmm(cat,marka,model)
{
  var res=0;
  var modelext='';

  var reg;
  if      ( (cat=='1') && (marka=='Mercedes-Benz') ) reg = new RegExp("^(A|B|C|CL|CLA|CLC|CLK|CLS|E|G|GL|GLA|GLE|ML|R|S|SL|SLK)$");
  else if ( (cat=='1') && (marka=='BMW')           ) reg = new RegExp("^(1|2|3|4|5|6|7|M)$");
  else if ( (cat=='3') && (marka=='Mercedes-Benz') ) reg = new RegExp("^(Sprinter)$");

  if ( reg && (reg.test(model)) )
    {
      res=1;
      if      ( marka=='BMW'                           ) { modelext='-серия'; }
      else if ( (marka=='Mercedes-Benz') && (cat=='1') ) { modelext='-класа'; }
    }

  return [res,modelext];
}

function changemm(cat,marka,model,ps,emptyfield)
{
  if ( cat && marka && model )
    {
      var cat_=cat.value;

      model.length=1;
      var flag=1;
      var i=0;
      while ( (i<autocmm[cat_].length)&&(flag==1) )
        {
          if ( autocmm[cat_][i][0]==marka.value )
            {
              var modclass='';
              var j=2; var k=1;
              while ( j<autocmm[cat_][i].length )
                {
                  var modval = autocmm[cat_][i][j]; var addflag = 1;
                  var mm=(0,''); if ( ps==2 ) mm=checkmm(cat_,marka.value,modval);
                  if ( modval=='' )
                    {
                      if ( emptyfield ) modval=emptyfield;
                    }
                  else if ( ps==1 )
                    {
                      if ( (cat_==1) && ((marka.value=='BMW')||(marka.value=='Mercedes-Benz')) && (modval.length==1) ) addflag=0;
                    }
                  else if ( (ps==2) && (mm[0]==1) )
                    {
                      modclass=modval;
                      if ( mm[1]!='-серия' ) modclass=modclass+' ';
                      modval=modval+mm[1];
                    }
                  else if ( modclass!='' )
                    {
                      reg = new RegExp("^"+modclass+".{1,}");
                      if ( reg.test(modval) ) { modval="\u00A0\u00A0\u00A0"+modval; }
                      else                    { modclass='';                           }
                    }
                  if ( addflag==1 )
                    {
                      model.options[k]=new Option(modval,autocmm[cat_][i][j]);
                      k++;
                    }
                  j++;
                }
              flag=0;
            }
          i++;
        }
    }
}

function changecmm(a)
{
  if ( document.search&&document.search.cat&&document.search.marka&&document.search.model )
    {
      var cat_=document.search.cat.value;
      if ( a==1 )
        {
          var cat1=document.getElementById('cat1');
          if ( cat1 ) cat1.innerHTML=cats[cat_];

          var cat2=document.getElementById('cat2');
          if ( cat2 ) cat2.innerHTML=catcnt[cat_];

          var cat3=document.getElementById('cat3');
          var cat4=document.getElementById('cat4');
          var cat5 = document.getElementById('cat5');
          if ( cat3 && cat4 && cat5 )
            {
              if ( (1<=cat_)&&(cat_<=5) )
                {
                  cat3.innerHTML='Марка';
                  cat4.innerHTML='Модел';
                  cat5.style.display='block';
                }
              else if ( (6<=cat_)&&(cat_<=12) )
                {
                  cat3.innerHTML='Вид техника';
                  cat4.innerHTML='Марка';
                  cat5.style.display='none';
                }

            }
          if ( autocmm[cat_] )
            {
              document.search.marka.innerHTML='<option value="">всички</option>\n';
              document.search.model.innerHTML='<option value="">всички</option>\n';
              var innerhtml='', markachar='' ,markachar_='';
              var i=0;
              while ( i<autocmm[cat_].length )
                {
                  if ( cat_<=5 )
                    {
                      markachar=autocmm[cat_][i][0].substr(0,1);
                      if ( markachar!=markachar_ )
                        {
                          if ( markachar_!='' ) innerhtml+='</optgroup>\n';
                          innerhtml+='<optgroup label="'+markachar+'">\n';
                          markachar_=markachar;
                        }
                    }
                  innerhtml+='<option value="'+autocmm[cat_][i][0]+'">'+autocmm[cat_][i][0]+'</option>\n';
                  i++;
                }
              if ( markachar_!='' ) innerhtml+='</optgroup>\n';
              document.search.marka.innerHTML+=innerhtml;
            }
        }
      else
        {
          if ( autocmm[cat_] )
            {
              document.search.model.innerHTML='<option value="">всички</option>\n';
              var innerhtml='', flag=1;
              var i=0;
              while ( (i<autocmm[cat_].length)&&(flag==1) )
                {
                  if ( autocmm[cat_][i][0]==document.search.marka.value )
                    {
                      var modclass='';
                      var j=2;
                      while ( j<autocmm[cat_][i].length )
                        {
                          var modval=autocmm[cat_][i][j];
                          var mm=checkmm(cat_,document.search.marka.value,modval);
                          if ( mm[0]==1 )
                            {
                              modclass=modval;
                              if ( mm[1]!='-серия' ) modclass=modclass+' ';
                              modval=modval+mm[1];
                            }
                          else if ( modclass!='' )
                            {
                              reg = new RegExp("^"+modclass+".{1,}");
                              if ( reg.test(modval) ) { modval="\u00A0\u00A0\u00A0"+modval; }
                              else                    { modclass='';                        }
                            }

                          innerhtml+='<option value="'+autocmm[cat_][i][j]+'">'+modval+'</option>\n';
                          j++;
                        }
                      flag=0;
                    }
                  i++;
                }
              document.search.model.innerHTML+=innerhtml;
            }
        }
    }
}

function pricenegcheck(priceneg,price)
{
  if ( priceneg && price )
    {
      if ( priceneg.checked )
        {
          if ( confirm ( 'ВНИМАНИЕ!!!\nВ случай, че изберете цена по договаряне,\nВашата обява ще бъде класирана след всички обяви с обявени цени!' ) )
            {
              price.value='';
            }
          else
            {
              priceneg.checked=false;
            }
        }
    }
}

function pricenegtype()
{
  if ( document.pub && document.pub.priceneg )
    document.pub.priceneg.checked=false;
}

//Phone change
function ShowPhonePopup(msg)
{
  var messObj=document.getElementById("phone_txt");
  var phonePopupObj=document.getElementById("phonePopup");
  if ( messObj && phonePopupObj )
    {
      messObj.innerHTML=msg;
      phonePopupObj.style.display = 'block';
    }
}
function HidePhonePopup()
{
  var phonePopupObj=document.getElementById("phonePopup");
  var messObj=document.getElementById("phone_txt");
  if ( messObj && phonePopupObj )
    {
      messObj.innerHTML="";
      phonePopupObj.style.display = 'none';
    }
}
function CheckPhone(phone)
{
  var patterns = /[^0-9]/g;
  var caretPos = phone.selectionStart;

  var replaced = phone.value.search(patterns) >= 0;
  if(replaced)
    {
      phone.value = phone.value.replace(patterns, '');
      phone.setSelectionRange(caretPos-1, caretPos-1);
    }

  if( phone.value.length>=10 )
    {
      if ( phone.value.match(/^(?:(?:(?:00)?359)|0)(?:87|88|89|98|99)\d{7}$/) )
        {
          ShowPhonePopup('<b>ВАЖНО</b>: С цел подсигуряване надеждността на публикуваните в сайта обяви, на посоченият от Вас телефон ще получите ключ, който трябва да въвдете на следващата стъпка.<br/>Получаването на ключа и публикуването на нормална обява е НАПЪЛНО БЕЗПЛАТНО!');
        }
      else
        {
          ShowPhonePopup('Моля, въведете <b>български мобилeн телефон</b> (GSM) за връзка с Вас.');
        }
    }
  else if (phone.value.length>1)
    {
      if ((phone.value.substring(0,2) == '08' || phone.value.substring(0,2) == '09' || phone.value.substring(0,4) == '3598' || phone.value.substring(0,4) == '3599' || phone.value.substring(0,6) == '003598' || phone.value.substring(0,6) == '003599'))
        HidePhonePopup();
      else
        ShowPhonePopup('Моля, въведете <b>мобилeн телефон</b> (GSM) за връзка с Вас.');
    }
  else
    {
      HidePhonePopup();
    }
}

function LoadPicts()
{
  var filelistObj=document.getElementById('filelist');
  if ( filelistObj && document.pub && document.pub.picts && (document.pub.picts.value!='') )
    {
      var pictshtml='';
      var picts=document.pub.picts.value.split('~');
      var i=0;
      while ( i<picts.length )
        {
          if ( picts[i] )
            {
              pictshtml+='<div class="preview">\n';
              pictshtml+='<a class="rotate-pic" href="#" title="Завърти снимката" alt="Завърти снимката"></a>\n';
              pictshtml+='<a class="delete-pic" alt="Изтриване на снимката" title="Изтриване на снимката" href="#">Delete</a>\n';
              pictshtml+='<div class="img" style="background-image: url('+picts[i]+');"></div>\n';
              pictshtml+='</div>\n';
            }
          i++;
        }
      filelistObj.innerHTML=pictshtml;
    }
}

function SavePicts()
{
  if ( document.pub.picts )
    {
      var pictslist='';
      var imgs=document.getElementsByClassName("img");
      if ( imgs )
        {
          var i=0;
          while ( (i<imgs.length) && (i<17) )
            {
              var imgbgr=imgs[i].style.backgroundImage;
              var imgname=imgbgr.match(/[1-9abcxyzuvw]\d{16}_[\da-zA-Z]{1,2}\.(pic|jpg|png|gif)/);
              if ( !imgname ) imgname=imgbgr.match(/[a-z\d]{32}_\d{1,3}\.(pic)/)
              if ( imgname && (imgname.length==2) )
                pictslist+=imgname[0];
              i++;
              if ( i<imgs.length ) pictslist+='/';
            }
          document.pub.picts.value=pictslist;
        }
    }
}

function pubadv()
{
  if ( document.pub && document.pub.subm )
    {
      SavePicts();
      document.pub.subm.disabled=true;
      document.pub.subm.innerHTML='ПУБЛИКУВАНЕ ...';
      document.pub.act.value='2';
      document.pub.submit();
    }
}

function HideShowKlima(id)
{
  var checkbox=document.getElementById(id);
  if (checkbox.disabled == true) { checkbox.disabled= false;}
  else {checkbox.disabled= true;}
}

function ChangeSelectedExtri(searchrescat)
{
  var options=document.getElementById('options'+searchrescat);
  var num=options.innerHTML.match(/blue|red/g);
  if (num)
    {
      document.getElementById('selected_extri'+searchrescat).innerHTML='('+num.length+')';
    }
  else
    {
      document.getElementById('selected_extri'+searchrescat).innerHTML='(0)';
    }
}

function extrich(position, searchrescat)
{
  var labelObj=document.getElementById('checkbox_'+position);
  var infoObj=document.getElementById('info_'+position);
  var extriObj=document.getElementById("extri");

  if ( labelObj && infoObj && extriObj && position )
    {
      var extraval='0';
      if ( infoObj.className == 'cbw' )
        {
          infoObj.className="cbb";
          labelObj.className="blue";
          extraval='1';
        }
      else
        {
          if (  infoObj.className =='cbb' )
            {
              infoObj.className="cbr";
              labelObj.className="red";
              extraval='2';
            }
          else
            {
              infoObj.className="cbw";
              labelObj.className="";
              extraval='0';
            }
        }

      extriObj.value=extriObj.value.substr(0,position-1) + extraval + extriObj.value.substr(position);

      if (searchrescat)
        {
          ChangeSelectedExtri(searchrescat);
          document.getElementById('fBtn').className = "btRed";
          document.getElementById('fBtn').disabled = false;
        }
    }
}

function showFilterItems(target){
  if(document.getElementById('options'+target).style.display != 'block') {
    document.getElementById('options'+target).style.display = 'block';
    if(document.getElementById('link'+target).className=='number'){
      document.getElementById('link'+target).className = "number open";
    }else{
      document.getElementById('link'+target).className = "open";
    }
  }else{
    document.getElementById('options'+target).style.display = 'none';
    if(document.getElementById('link'+target).className=='number'){
      document.getElementById('link'+target).className = "number";
    }else if(document.getElementById('link'+target).className=='number open'){
      document.getElementById('link'+target).className = "number";
    }else{
      document.getElementById('link'+target).className = "";
    }
  }
}

function setnup(n)
{
  var n0 = document.getElementById("nup0");
  var n1 = document.getElementById("nup1");
  var n2 = document.getElementById("nup2");
  if ( n && n0 && n1 && n2 )
    {
      n.value='';
      if ( n0.checked )
        n.value=n.value+n0.value;
      if ( n1.checked )
        n.value=n.value+n1.value;
      if ( n2.checked )
        n.value=n.value+n2.value;
      n.value=n.value+'3';
    }
}

function removeDisabled()
{
  var i=0;
  while ( i<document.search.length )
    {
      if ( document.search[i].id!='fBtn' )
        document.search[i].disabled=false;
      i++;
    }
}

function removeEmpty()
{
  var i=0;
  while ( i<document.search.length )
    {
      if (document.search[i].value == "")
        document.search[i].disabled=true;
      i++;
    }

  if (document.search.page && (document.search.page.value == 1))
    document.search.page.disabled=true;

  // if (document.search.cat && (document.search.cat.value == 1))
  //   document.search.cat.disabled=true;

  if (document.search.sort && (document.search.sort.value == 1))
    document.search.sort.disabled=true;

  if (document.search.km && (document.search.km.value == 0))
    document.search.km.disabled=true;

  if ( document.search.extri ) {
    var tmplExtri = document.search.extri.value.replace(/0/g, '');
    if (tmplExtri == '')
      document.search.extri.disabled=true;
  }
}

function ChangeButton()
{
  document.getElementById('fBtn').className = "btRed";
  document.getElementById('fBtn').disabled = false;

  var i=0;
  while ( i<document.search.length )
    {
      if (document.search[i].type == "select-one")
        {
          document.search[i].removeEventListener("change", ChangeButton );
        }
      else if (document.search[i].type == "text")
        {
          document.search[i].removeEventListener("keyup", ChangeButton );
        }
      else if (document.search[i].type == "checkbox")
        {
          document.search[i].removeEventListener("click", ChangeButton );
        }
      i++;
    }
}

function addListeners()
{
  var i=0;
  while ( i<document.search.length )
    {
      if (document.search[i].type == "select-one")
        {
          document.search[i].addEventListener("change", ChangeButton );
        }
      else if (document.search[i].type == "text")
        {
          document.search[i].addEventListener("keyup", ChangeButton );
        }
      else if (document.search[i].type == "checkbox")
        {
          document.search[i].addEventListener("click", ChangeButton );
        }
      i++;
    }
}

function callDealer(flag){
  if (flag) {
      document.getElementById('callDealerBtn1').style.display = 'none';
      document.getElementById('callDealerForms1').style.display = 'inline-block';
      document.getElementById('messageStatus1').style.display = 'none';
    }
  else {
      document.getElementById('callDealerBtn').style.display = 'none';
      document.getElementById('callDealerForms').style.display = 'inline-block';
      document.getElementById('messageStatus').style.display = 'none';
    }
}

function MakeContact (flag){
  if (flag){
      $("#contact-form1").each(function(){
        $(this).find('.changecolor').each(function(){
          $(this).parent().removeClass( "errorForm" );
        });
      });

      var request = $.ajax({
        url: "/ajaxs?"+$(document.contact1).serialize(),
        method: "GET",
        dataType: 'html'
      });

      request.done(function( data ) {
        var json = jQuery.parseJSON( data );

        if ( json.error == "true")
          {
            $('#messageFail1').text('Попълнете полетата коректно!');
            if ( json.error_field=="f4" ) {
              $('#messageFail1').text('Трябва да се съгласите с Общите условия и политиката ни за защита на личните данни!');
            }
            else {
              $("[name='"+json.error_field+"']").parent().addClass( "errorForm" );
            }

            $('#messageFail1').show();
          }
        else
          {
            $('#messageFail1').hide();
            sendDealerMessage(flag);

            $("#contact-form1").each(function(){
              $(this).find('.changecolor').each(function(){
                $(this).parent().removeClass( "errorForm" );
                $(this).val("");
              });
            });
            $("#contact-form1").find("input[name='f4']").attr('checked', false);
          }
      });
    }
  else {
      $("#contact-form").each(function(){
        $(this).find('.changecolor').each(function(){
          $(this).parent().removeClass( "errorForm" );
        });
      });

      var request = $.ajax({
        url: "/ajaxs?"+$(document.contact).serialize(),
        method: "GET",
        dataType: 'html'
      });

      request.done(function( data ) {
        var json = jQuery.parseJSON( data );

        if ( json.error == "true")
          {
            $('#messageFail').html('Попълнете полетата коректно!');
            if ( json.error_field=="f4" ) {
              $('#messageFail').html('Трябва да се съгласите с Общите условия<br/>и политиката ни за защита на личните данни!');
            }
            else {
              $("[name='"+json.error_field+"']").parent().addClass( "errorForm" );
            }
            $('#messageFail').show();
          }
        else
          {
            $('#messageFail').hide();
            sendDealerMessage();

            $("#contact-form").each(function(){
              $(this).find('.changecolor').each(function(){
                $(this).parent().removeClass( "errorForm" );
                $(this).val("");
              });
            });
            $("#contact-form").find("input[name='f4']").attr('checked', false);
          }
      });
    }
}

function sendDealerMessage(flag){
  if (flag){
      document.getElementById('callDealerBtn1').style.display = 'block';
      document.getElementById('callDealerBtn1').innerHTML = 'Изпрати нов Email<img src="/images/ico/email-white.png" />';
      document.getElementById('callDealerForms1').style.display = 'none';
      document.getElementById('messageStatus1').style.display = 'block';
      document.getElementById('messageStatus1').style.opacity = '.1';
      document.getElementById('messageStatus1').style.marginTop = '8px';
      document.getElementById('messageStatus1').style.borderWidth = '1px';
      document.getElementById('messageStatus1').style.height = '23px';

      $('#messageStatus1').animate({ opacity: 1,}, 500,
        function() {
          timeVar = setTimeout(function(){
            $('#messageStatus1').animate({
              height: 0,
              marginTop:0,
              borderWidth:0,
              }, 400, function() {
            });
          }, 3000);
        });
    }
  else{
      document.getElementById('callDealerBtn').style.display = 'block';
      document.getElementById('callDealerBtn').innerHTML = 'Изпрати нов Email<img src="/images/ico/email-white.png" />';
      document.getElementById('callDealerForms').style.display = 'none';
      document.getElementById('messageStatus').style.display = 'block';
      document.getElementById('messageStatus').style.opacity = '.1';
      document.getElementById('messageStatus').style.marginTop = '8px';
      document.getElementById('messageStatus').style.borderWidth = '1px';
      document.getElementById('messageStatus').style.height = '23px';

      $('#messageStatus').animate({ opacity: 1,}, 500,
        function() {
          timeVar = setTimeout(function(){
            $('#messageStatus').animate({
              height: 0,
              marginTop:0,
              borderWidth:0,
              }, 400, function() {
            });
          }, 3000);
        });
    }
}

function notepad_act (adv,refresh,callflag){

  var request = $.ajax({
    url: "/ajaxs?mode=notepad&adv="+adv,
    method: "GET",
    dataType: 'html'
  });

  request.done(function( data ) {
    var json = jQuery.parseJSON( data );

    if (json.result)
      {
        var selector="#notepad_"+adv;
        if (callflag=="1")
          {
            selector="#saveLink";
            json.textin = json.textin.replace("&#8250; ", "");
            json.textin = json.textin.replace("x ", "");
          }

        $(selector).html(json.textin);
        $(selector).addClass(json.class);
        $(selector).removeClass(json.classRem);

        if (refresh==1)
          window.location.reload();
      }
    else
      {
        alert(json.message);
      }
  });
}

function popitup(url) {
  var width_tmp=(window.innerWidth/2)-250;
  var height_tmp=(window.innerHeight/2)-200;
  newwindow=window.open(url,'name','width=500,height=400,screenx='+width_tmp+',screeny='+height_tmp);
  if (window.focus) {newwindow.focus()}
  return false;
}

function openSendAdByEmail(){
  if(document.getElementById('sendAdByEmail').style.display != 'block'){
    document.getElementById('sendAdByEmail').style.display = 'block';
    document.getElementById('sendAdByEmail').style.opacity = 0;

    $('#sendAdByEmail').animate({
    opacity: 1,
    }, 500, function() {
      //
    });
  }else{
    closeSendAdByEmail();
    $('#messageStatusShare').show();
    $('#messageStatusShare').animate({ opacity: 1,}, 500,
    function() {
      timeVar = setTimeout(function(){
        $('#messageStatusShare').animate({
          height: 0,
          marginTop:0,
          borderWidth:0,
          }, 400, function() {
        });
      }, 3000);
    });
  }
}
function closeSendAdByEmail(){
  document.getElementById('sendAdByEmail').style.display = 'none';
}

function ShareAdvert(){

  $("#share_advert").each(function(){
    $(this).find('.changecolor').each(function(){
      $(this).removeClass("errorForm" );
    });
  });

  var request = $.ajax({
    url: "/ajaxs?"+$(document.share).serialize(),
    method: "GET",
    dataType: 'html'
  });

  request.done(function( data ) {
    var json = jQuery.parseJSON( data );
    if ( json.error == "true")
      {
        $('#messageFailShare').html('Попълнете полетата коректно!');
        if ( json.error_field=="accept" ) {
          $('#messageFailShare').html('Трябва да се съгласите с Общите условия и политиката ни за защита на личните данни!');
        }
        else {
          $("[name='"+json.error_field+"']").addClass( "errorForm" );
        }
        $('#messageFailShare').show();
      }
    else
      {
        $('#messageFailShare').hide();
        openSendAdByEmail();

        $("#share_advert").each(function(){
          $(this).find('.changecolor').each(function(){
            $(this).removeClass( "errorForm" );
            $(this).val("");
          });
        });
        $("#share_advert").find("input[name='accept']").attr('checked', false);
      }
  });
}

function ShowLastAdverts(){
  $('#last_adverts_notepad_div').hide();
  $('#last_adverts_notepad_img').hide();
  $('#link_last_adverts_notepad').removeClass("selected");
  $('#link_last_adverts').addClass("selected");
  $('#last_adverts_div').show();
  $('#last_adverts_img').show();
}

function ShowLastNotepadAdverts(){
  $('#last_adverts_div').hide();
  $('#last_adverts_img').hide();
  $('#link_last_adverts').removeClass("selected");
  $('#link_last_adverts_notepad').addClass("selected");
  $('#last_adverts_notepad_div').show();
  $('#last_adverts_notepad_img').show();
}

function getCheckedValue(radioObj)
{
  if (!radioObj) return "";
  var radioLength = radioObj.length;
  if ( radioLength == undefined )
    if ( radioObj.checked )
      return radioObj.value;
    else
      return "";
  for ( var i = 0; i < radioLength; i++ )
    {
      if ( radioObj[i].checked )
        {
          return radioObj[i].value;
        }
    }
  return "";
}

function setpaybutton()
{
  if( document.payform && document.payform.psum )
    {

      var paybuttonObj=document.getElementById('paybutton');
      if ( paybuttonObj )
        {
          if ( document.payform.psum.value!='' ) paybuttonObj.innerHTML=' от '+document.payform.psum.value+' лв.';
          else                                   paybuttonObj.innerHTML='';
        }
    }
}

function payrequest()
{
  if( document.payform )
    {
      if ( document.payform.chkcond && document.payform.chkcond.checked  )
        {
          var ptypeval=getCheckedValue(document.payform.ptype);
          if ( ptypeval>0 )
            {
              if ( document.payform.psum )
                {
                  var psumval=document.payform.psum.value;
                  var psumreg=/^\d{1,4}$/;
                  if ( psumreg.test(psumval) && (10<=psumval) && (psumval<=10000) )
                    {
                      document.payform.actions.value='2';
                      document.payform.submit();
                    }
                  else
                    {
                      alert('Сумата трябва да е цяло число от 10 до 10000 лв.');
                    }
                }
            }
          else
            {
              alert('Моля изберете метод на плащане!');
            }
        }
      else
        {
          alert('Не сте се съгласили с общите условия!');
        }
    }
}

function payinvoice()
{
  if( document.invoiceform && document.invoiceform.f1list && invlist )
    {
      var ind=document.invoiceform.f1list.options[document.invoiceform.f1list.selectedIndex].value;
      if ( invlist[ind] )
        {
          if ( document.invoiceform.f1 ) document.invoiceform.f1.value=invlist[ind][0];
          if ( document.invoiceform.f2 ) document.invoiceform.f2.value=invlist[ind][1];
          if ( document.invoiceform.f3 ) document.invoiceform.f3.value=invlist[ind][2];
          if ( document.invoiceform.f4 ) document.invoiceform.f4.value=invlist[ind][3];
          if ( document.invoiceform.f5 ) document.invoiceform.f5.value=invlist[ind][4];
        }
    }
}

function ChangeHeader()
{
  var md = new MobileDetect(window.navigator.userAgent);
  if ( md && md.mobile() && (md.tablet()==null) )
    {
      var th=document.getElementsByClassName('topHeader');
      var tho=document.getElementById('topHeaderOffs')
      if ( th && (th.length==1) && tho )
        {
          th[0].style.position='fixed';
          tho.style.marginTop='125px';
        }
    }
}

function getCookie(c_name)
{
if (document.cookie.length>0)
  {
  c_start=document.cookie.indexOf(c_name + "=");
  if (c_start!=-1)
    {
    c_start=c_start + c_name.length+1;
    c_end=document.cookie.indexOf(";",c_start);
    if (c_end==-1) c_end=document.cookie.length;
    return unescape(document.cookie.substring(c_start,c_end));
    }
  }
return "";
}

function changeRegType(regtype)
{
  var em_reg=document.getElementById('email_reg');
  var em_info=document.getElementById('email_info');
  var em_txt1=document.getElementById('email_txt1');
  var em_txt2=document.getElementById('email_txt2');
  var ph_reg=document.getElementById('phone_reg');
  var ph_info=document.getElementById('phone_info');
  var ph_txt1=document.getElementById('phone_txt1');
  var ph_txt2=document.getElementById('phone_txt2');
  var ps_reg=document.getElementById('pass_reg');
  if ( em_reg && em_info && em_txt1 && em_txt2 && ph_reg && ph_info && ph_txt1 && ph_txt2 && ps_reg  )
    {
      if ( regtype==1 ) {
        em_reg.style.display='block';
        em_info.style.display='block';
        em_txt1.style.color='#000';
        em_txt2.style.color='#008f41';
        ph_reg.style.display='none';
        ph_info.style.display='none';
        ph_txt1.style.color='#999';
        ph_txt2.style.color='#999';
      }
      else if ( regtype==2 ) {
        em_reg.style.display='none';
        em_info.style.display='none';
        em_txt1.style.color='#999';
        em_txt2.style.color='#999';
        ph_reg.style.display='block';
        ph_info.style.display='block';
        ph_txt1.style.color='#000';
        ph_txt2.style.color='#008f41';
      }
      ps_reg.style.display='block';
    }
}

function changeLogType(logtype)
{
  if ( ((logtype==1)||(logtype==2)) && document.login && document.login.regtype ) {
    document.login.regtype.value=logtype;
    if ( document.login.act ) document.login.act.value='1';
    if ( document.login.email_phone ) document.login.email_phone.value='';
    if ( document.login.passw ) document.login.passw.value='';
    document.login.submit();
  }
}

function openLogPopup() {
  var logpopupObj=document.getElementById('logpopup');
  if ( logpopupObj ) {
      logpopupObj.style.opacity = 1;
      logpopupObj.style.display = 'block';

      logpopupObj.innerHTML='<iframe class="logPopupFrame" src="./loginregistrations" name="loginframe"  marginwidth=0 marginheight=0 frameborder=0 scrolling="no"></iframe>';
  }
}

function showDetails(link,url) {
  if ( link ) {
    link.href=url;
  }
}

document.addEventListener("DOMContentLoaded", function() {

  var showAllCities = document.querySelectorAll('.aCity .all');
  if (showAllCities.length > 0) {
    showAllCities[0].addEventListener('click', function (e) {
      var cities = document.querySelectorAll('.aCity .hidden');
      for (var j = 0; j < cities.length; j++) {
        cities[j].classList.remove('hidden');
      }
      this.classList.add('hidden');
    })
  }

  var stars = document.querySelectorAll('.rating.enabled svg');
  var ratingDiv = document.querySelector('.rating');

  function setRatingBackground(ratingDiv) {
    if (ratingDiv) {
      var rating = parseFloat(ratingDiv.getAttribute('data-rating'));
      var percent = 100*rating/5;
      ratingDiv.style.background = "linear-gradient(to right, gold " + percent + "%, transparent 0%)";
    }
  }

  setRatingBackground(ratingDiv);

  function clearRatingBackground(ratingDiv) {
    ratingDiv.style.background = 'none';
  }

  for (var i = 0; i < stars.length; i++) {
    var enterFn = function() {
      if (stars) {
        var sr = parseInt(this.getAttribute('data-rating'));

        for (var j = 0; j < stars.length; j++) {
          stars[j].classList.add(sr > j ? 'hover' : 'stroke_hover');
        }
        clearRatingBackground(ratingDiv);
      }
    }

    var leaveFn = function() {
      if (stars) {

        for (var j = 0; j < stars.length; j++) {
          stars[j].classList.remove('hover', 'stroke_hover');
        }
        setRatingBackground(ratingDiv);
      }
    }

    var clickFn = function() {
      if (stars) {
        ratingDiv.classList.remove('enabled');
        leaveFn();
        stars = null;
        var sr = parseInt(this.getAttribute('data-rating'));
        var id = ratingDiv.getAttribute('data-id');

        const req = new XMLHttpRequest();

        req.onreadystatechange = function (event) {
          if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200) {
              leaveFn();
              stars = null;
              var resp = JSON.parse(this.response);
              ratingDiv.setAttribute('data-rating', resp.rating);
              setRatingBackground(ratingDiv);
              if (document.getElementById('rating')) {
                document.getElementById('rating').innerText = resp.rating;
                document.getElementById('votes').innerText = resp.votes;
              }
              if (sr == 5) {
                alert("Благодарим Ви за добрата оценка");
              } else {
                alert("Благодарим Ви за рейтинга. Ще се радваме ако дадете по-висока оценка :)");
              }
            }
          }
        };

        req.open('POST', '/rating', true);
        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        req.send("v=" + sr + "&id=" + id);

      }
    }


    function addListeners() {
      stars[i].addEventListener('mouseenter', enterFn.bind(stars[i]));
      stars[i].addEventListener('mouseleave', leaveFn.bind(stars[i]));
      stars[i].addEventListener('click', clickFn.bind(stars[i]));
    }

    addListeners();


  }


  var seoEditBtn = document.querySelector('.seo #editBtn');
  var seoPreviewBtn = document.querySelector('.seo #previewBtn');
  var seoSaveBtn = document.querySelector('.seo #saveBtn');
  var seoEditDiv = document.querySelector('.seo #editdiv');

  if (seoEditBtn) {

    seoEditBtn.addEventListener('click' , function (e) {
    seoEditDiv.classList.remove('hidden');
    tinymce.init({
      selector:'#seo_txt',
      plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime media nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
      ],
      toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
      toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
      image_advtab: true,
      theme: 'modern',
    });
  });
  }

  if (seoPreviewBtn) {
    seoPreviewBtn.addEventListener('click', function (e) {
      var txt = tinymce.get('seo_txt').getContent();
      document.querySelector('.seo #txt').innerHTML = txt;
    });
  }

  if (seoSaveBtn) {
    seoSaveBtn.addEventListener('click', function (e) {
    var txt = tinymce.get('seo_txt').getContent();
    var cat = document.querySelector('.seo [name=cat]').value;

    $.ajax({
      url: "/ajaxs",
      method: "POST",
      data: {
        mode: 'seo',
        txt: txt,
        cat: cat
      }
    }).done(function( data ) {
      if (data.result == 'true') {
        document.querySelector('.seo #txt').innerHTML = txt;
        seoEditDiv.classList.add('hidden');

      }
    })

  })
  }
});

function showPhone(string, ida) {
  const str = string.replace(/\D/g,"").replace(/^(\d{4})(\d{3})/,"$1 $2 ");

  document.querySelectorAll('.phoneNumber').forEach( function (phone) {
    phone.innerHTML = str;
  });

  ga('send', {
    hitType: 'event',
    eventCategory: 'Adverts',
    eventAction: 'CallButtonClicked',
    eventLabel: 'Desktop'
  });
  str
  document.getElementById('rtb_details').src = 'https://creativecdn.com/tags?id=pr_Tv7VoMcdIIy0LF1syDBu_orderstatus2_0_' + str.replace(/ /g, '')  + '_' + ida + '&cd=default';
}

$( document ).ready(function() {
  (function($) {
    var $window = $(window);
    var $videoWrap = $('.video-wrap');
    if ($videoWrap.length == 1) {
      var $video = $('.video');
      var videoHeight = $video.outerHeight();

      $window.on('scroll',  function() {
        var windowScrollTop = $window.scrollTop();
        var videoBottom = videoHeight + $videoWrap.offset().top;

        if (windowScrollTop > videoBottom) {
          $videoWrap.height(videoHeight);
          $video.addClass('stuck');
        } else {
          $videoWrap.height('auto');
          $video.removeClass('stuck');
        }
      });
    }
  }(jQuery));
});


